import React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import BugsnagPluginExpress from "@bugsnag/plugin-express"
import { AuthenticationError, AuthorizationError } from "blitz"

const ignoreErrorClasses = [AuthenticationError, AuthorizationError]

let expressMiddleware: any = null

if (process.env.NODE_ENV !== "test") {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY! || "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF",
    plugins: [BugsnagPluginExpress, new BugsnagPluginReact(React)],
    onError: (event) => {
      if (ignoreErrorClasses.some((errorClass) => event?.errors[0]?.errorClass === errorClass.name))
        return false
    },
    releaseStage: process.env.APP_ENV!,
    enabledReleaseStages: ["production", "staging"],
  })
  expressMiddleware = Bugsnag.getPlugin("express")?.requestHandler
}

export const bugsnagExpressMiddleware = (req, res) => {
  if (!expressMiddleware) {
    return
  }
  return new Promise((resolve, reject) => {
    expressMiddleware(req, res, (result) => {
      if (result instanceof Error) {
        return reject(result)
      }

      return resolve(result)
    })
  })
}

export default Bugsnag
