import { withBlitz } from "app/blitz-client"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"

import {
  AppProps,
  ErrorBoundary,
  ErrorComponent as DefaultErrorComponent,
  ErrorFallbackProps,
} from "@blitzjs/next"

import Bugsnag from "integrations/bugsnag"
import * as Sentry from "@sentry/nextjs"
import { AuthenticationError, AuthorizationError } from "blitz"

export default withBlitz(function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
    >
      {getLayout(<Component {...pageProps} />)}
    </ErrorBoundary>
  )
})

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    if (window) {
      window.location.href = "/api/auth/google"
    }
    return <DefaultErrorComponent statusCode={401} title="Sorry, you are not authenticated" />
  } else if (error instanceof AuthorizationError) {
    return (
      <DefaultErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    console.error(error)
    Bugsnag.notify(error)
    Sentry.captureException(error)
    return (
      <DefaultErrorComponent
        statusCode={error.statusCode || 400}
        title={error.message || error.name}
      />
    )
  }
}
